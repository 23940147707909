import React from "react"
import { graphql, Link } from "gatsby"
// import { Helmet } from 'react-helmet'

import { MDXRenderer } from "gatsby-plugin-mdx"

import Layout from '../components/layout';
import SEO from '../components/seo';
import CTA from '../components/blog/cta';
import Recommended from '../components/blog/recommended';
import Header from '../components/blog/header';
import BlogCard from "../components/blog/blogcard";

import "../styles/content.css"


// Blog post from wordpress
export default function Post({data}) {
  const { allWpPost } = data;
  const post = allWpPost.nodes[0]

  if (!post || !post.title){
    return <div>No post found</div>
  }

  const author = post.author.node


  const seoComponent = <SEO title={post.title}
    description={post.excerpt ? post.excerpt.replace(/<[^>]+>/g, '') : ''}
    path={`/post/${post.slug}/`}
    imageUrl={post.featuredImage?.node?.link}
    generateImage={post.featuredImage ? false : true}
  />


  return (
    <Layout title={post.title}
      subsubtitle={''}
      seoComponent={seoComponent}
      includeBanner={false} lightHeader={false}
    >
      <div className="content-body markdown-body max-w-5xl mx-auto">
        <Header title={post.title} subtitle={post.dateGmt ? post.dateGmt.split('T')[0] : ''} />

        {/* {post.featuredImage?.node?.mediaItemUrl ? (
          <div className="text-center mb-4">
            <img src={post.featuredImage?.node?.mediaItemUrl} alt={post.title} className="w-full rounded-lg max-w-xl mx-auto" />
          </div>
        ) : ''} */}

        <div
          className="mt-4 wordpress-content"
          dangerouslySetInnerHTML={{__html: post.content}}
        />
      </div>

      <div className="mt-10">
        <CTA type="post"/>
      </div>

      {/* <Recommended nodes={recommended.edges}/> */}
      {data.recentWp.nodes.length ? (
        <div className="mx-auto mt-12">
          <div id="recent" className="">
            <h2 className="text-3xl font-semibold flex items-center">Recently Published</h2>
            <div className="">
              <ul className={`mt-4 grid sm:grid-cols-3 gap-x-8 gap-y-4`}>
                {data.recentWp.nodes.map((node, index) => (
                  <BlogCard key={node.slug} node={node} mini={true}/>
                ))}
              </ul>
            </div>
          </div>
        </div>
      ) : ''}

    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    allWpPost(
      limit: 1,
      filter: {slug: {eq: $slug}}
    ) {
      nodes {
        id
        date
        slug
        title
        status
        dateGmt
        tags {
          nodes {
            name
            slug
            count
          }
        }
        author {
          node {
            name
            firstName
            slug
            description
            avatar{
              url
            }
          }
        }
        featuredImage {
          node {
            link
            mediaItemUrl
          }
        }
        modified
        content
        excerpt
      }
    }
    recentWp: allWpPost(
      sort: {fields: date, order: DESC}
      limit: 6
    ) {
      nodes {
        id
        date
        slug
        title
        status
        excerpt
        dateGmt
        tags {
          nodes {
            name
            slug
            count
          }
        }
        featuredImage {
          node {
            link
          }
        }
        author {
          node {
            name
            slug
            firstName
            avatar {
              url
            }
          }
        }
      }
    }
  }
`
